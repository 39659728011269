#treeTable>div>table>thead>tr>th {
  text-align: center;
  background: #dee2e6;
  color: #212529;
  height: 40px;
  width: 100px;
  font-size: 14px;
}

#treeTable>div>table>thead>tr>th>div {
  display: block;
}

#treeTable>div>table>tbody>tr>td {
  text-align: center;
  width: 100px;
}

#treeTable>div>table>tbody>tr>td {
  /* border: 1px solid rgb(200, 200, 200); */
  border: 1px solid rgb(255, 255, 255);
  padding: 1px;
}

#treeTable>div>div>div>div>table>thead>tr>th {
  flex-direction: column;
  text-align: center;
  border-width: thin;
  padding: inherit;
  font-size: 14px;
  background-color: #dddddd;
  width: 150px;

}

#treeTable>div>div>div>table>tbody>tr>td {
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  width: 150px;

}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #8ba7ff;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-treetable .p-treetable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot>tr>td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
}

.p-treetable .p-sortable-column {
  outline-color: #c7d2fe;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #343a40;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #4338ca;
  background: #eef2ff;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #343a40;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #343a40;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #eef2ff;
  color: #4338ca;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4338ca;
}

.p-treetable .p-treetable-tbody>tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #c7d2fe;
}

.p-treetable .p-treetable-tbody>tr>td {
  text-align: left;
  border: 1px solid #dee2e6;
  padding: 1rem 1rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-treetable .p-treetable-tbody>tr.p-highlight {
  background: #eef2ff;
  color: #4338ca;
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
  color: #4338ca;
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
  color: #4338ca;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}

.p-treetable .p-column-resizer-helper {
  background: #6366f1;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-treetable .p-treetable-tbody>tr.p-tag-level2 {
  background: #f5f6ce;
}

.p-treetable .p-treetable-tbody>tr.p-tag-level3 {
  background: #81daf5;
}

.p-treetable .p-treetable-tbody>tr.p-tag-level4 {
  background: #ff980094;
}

.p-treetable .p-treetable-tbody>tr .p-tag-danger {
  background: #d32f2f;
}

.p-treetable .p-treetable-tbody>tr.p-highlight {
  background: #eef2ff;
  color: #4338ca;
}

#userTable>div>table>thead>tr>th {
  text-align: center;
  background: #dee2e6;
  color: #212529;
  height: 40px;
  width: 100px;
}

#userTable>div>table>thead>tr>th>div {
  display: block;
}

#userTable>div>table>tbody>tr>td {
  text-align: center;
  width: 100px;
}

#userTable>div>table>tbody>tr {
  text-align: center;
  height: 30px;
}

/* #userTable > */
.p-column-filter-row .p-column-filter-element {
  flex: 1 1 auto;
  width: 100%;
}

.blackUser {
  background-color: Red;
}

.connectionUser {
  background-color: blue;
}

.row-balance-color-first {
  background-color: #f5a9a9;
}

.row-balance-color-300 {
  background-color: #f5f6ce;
}

.row-balance-color-999 {
  background-color: #81daf5;
}

.row-balance-color-1000 {
  background-color: #d7df01;
}

.row-balance-deposit {
  background-color: #d0e4ff;
}

.row-balance-withdraw {
  background-color: #f8e0e0;
}

.row-balance-cansel {
  background-color: #bbbbbb;
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-tfoot>tr>td {
  border: 1px solid rgb(200, 200, 200);
  display: grid;
}

.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 6px;
  /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #6366f1;
  background: #6366f1;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.pi {
  font-family: "primeicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-button {
  /* margin:  2px; */
  display: table-row;
}

.p-tag {
  background: #2196f3;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
}

.p-tag.p-tag-success {
  background-color: #689f38;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #0288d1;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #fbc02d;
  color: #212529;
}

.p-tag.p-tag-danger {
  background-color: #d32f2f;
  color: #ffffff;
}

.p-message.p-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-message.p-message-warn .p-message-icon {
  color: #cc8925;
}

.p-message.p-message-warn .p-message-close {
  color: #cc8925;
}

.p-message.p-message-error {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
}

.p-message.p-message-error .p-message-icon {
  color: #ff5757;
}

.p-message.p-message-error .p-message-close {
  color: #ff5757;
}

.p-message.p-message-info {
  background: #e9e9ff;
  border: solid #696cff;
  border-width: 0 0 0 6px;
  color: #696cff;
}

.p-message.p-message-info .p-message-icon {
  color: #696cff;
}

.p-message.p-message-info .p-message-close {
  color: #696cff;
}

.p-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #1ea97c;
}

.p-message-success .p-message-icon {
  color: #1ea97c;
}

.p-message-success .p-message-close {
  color: #1ea97c;
}

.p-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-tooltip {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.p-tooltip .p-tooltip-text {
  background-color: white;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  bottom: 1px;
  border-top-color: #323232;
}

/* .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  top: 1px;
  border-bottom-color: #323232;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #323232;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  left: 1px;
  border-right-color: #323232;
} */

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--blue-500);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--green-500);
}

.green-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--green-500);
}

.yellow-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--yellow-500);
}

.yellow-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--yellow-500);
}

.cyan-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--cyan-500);
}

.cyan-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--cyan-500);
}

.pink-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--pink-500);
}

.pink-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--pink-500);
}

.indigo-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--indigo-500);
}

.indigo-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--indigo-500);
}

.teal-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--teal-500);
}

.teal-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--teal-500);
}

.bluegray-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--bluegray-500);
}

.bluegray-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--bluegray-500);
}

.purple-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--purple-500);
}

.purple-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--purple-500);
}

/* date view  */

.dataview-demo .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.dataview-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.dataview-demo .product-description {
  margin: 0 0 1rem 0;
}

.dataview-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.dataview-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.dataview-demo .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.dataview-demo .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.dataview-demo .product-list-item .product-list-detail {
  flex: 1 1;
}

.dataview-demo .product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.dataview-demo .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

.dataview-demo .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.dataview-demo .product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.dataview-demo .product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;
  padding: 2rem;
}

.dataview-demo .product-grid-item .product-grid-item-top,
.dataview-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataview-demo .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.dataview-demo .product-grid-item .product-grid-item-content {
  text-align: center;
}

.dataview-demo .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .dataview-demo .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .dataview-demo .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .dataview-demo .product-list-item .product-list-detail {
    text-align: center;
  }

  .dataview-demo .product-list-item .product-price {
    align-self: center;
  }

  .dataview-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .dataview-demo .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #6366f1;
  background: #6366f1;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#fixTable>.p-datatable {
  position: relative;
}

/*
#treeTable >  div > table > thead > tr > th > div{
  display: block;
}  */

#fixTable>div>div>table>thead>tr {
  background: antiquewhite;
}

#fixTable>div>div>table>thead>tr>th {
  flex-direction: column;
  text-align: center;
}

#fixTable>div>div>table>tbody>tr>td {
  flex-direction: column;
  text-align: center;

  height: 60px;
}

/*
#fixTable > .p-datatable-scrollable .p-datatable-thead>tr>th .p-datatable-scrollable .p-datatable-tbody>tr>td, .p-datatable-scrollable .p-datatable-tfoot>tr>td`{
} */

#fixTable>.p-datatable table {
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
}

#fixTable>.p-datatable .p-sortable-column {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#fixTable>.p-datatable .p-sortable-disabled {
  cursor: auto;
}

#fixTable>.p-datatable .p-datatable-thead>tr>th {
  cursor: auto;
}

#fixTable>.p-datatable .p-sortable-column .p-column-title,
#fixTable>.p-datatable .p-sortable-column .p-sortable-column-badge,
#fixTable>.p-datatable .p-sortable-column .p-sortable-column-icon {
  vertical-align: middle;
}

#fixTable>.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#fixTable>.p-datatable-responsive-scroll>.p-datatable-wrapper {
  overflow-x: auto;
}

#fixTable>.p-datatable-auto-layout>.p-datatable-wrapper>table,
#fixTable>.p-datatable-responsive-scroll>.p-datatable-wrapper>table {
  table-layout: auto;
}

#fixTable>.p-datatable-hoverable-rows .p-selectable-row {
  cursor: pointer;
}

#fixTable>.p-datatable-drag-selection-helper {
  position: absolute;
  z-index: 99999999;
}

#fixTable>.p-datatable-scrollable .p-datatable-wrapper {
  position: relative;
  overflow: auto;
}

#fixTable>.p-datatable-scrollable .p-datatable-tbody,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot,
#fixTable>.p-datatable-scrollable .p-datatable-thead {
  display: block;
}

#fixTable>.p-datatable-scrollable .p-datatable-tbody>tr,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot>tr,
#fixTable>.p-datatable-scrollable .p-datatable-thead>tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

#fixTable>.p-datatable-scrollable .p-datatable-tbody>tr>td,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-scrollable .p-datatable-thead>tr>th {
  display: flex;
  flex: 1 1;
  align-items: center;
}

#fixTable>.p-datatable-scrollable .p-datatable-thead {
  top: 0;
}

#fixTable>.p-datatable-scrollable .p-datatable-frozen-tbody,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot,
#fixTable>.p-datatable-scrollable .p-datatable-thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

#fixTable>.p-datatable-scrollable .p-datatable-tfoot {
  bottom: 0;
}

#fixTable>.p-datatable-scrollable .p-frozen-column {
  position: -webkit-sticky;
  position: sticky;
  background: inherit;
}

#fixTable>.p-datatable-scrollable th.p-frozen-column {
  z-index: 1;
}

#fixTable>.p-datatable-scrollable-both .p-datatable-tbody>tr>td,
#fixTable>.p-datatable-scrollable-both .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-scrollable-both .p-datatable-thead>tr>th,
#fixTable>.p-datatable-scrollable-horizontal .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-scrollable-horizontal .p-datatable-thead>tr>th .p-datatable-scrollable-horizontal .p-datatable-tbody>tr>td {
  flex: 0 0 auto;
}

#fixTable>.p-datatable-flex-scrollable,
.p-datatable-flex-scrollable .p-datatable-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#fixTable>.p-datatable-flex-scrollable .p-datatable-wrapper {
  flex: 1 1;
}

#fixTable>.p-datatable-scrollable .p-rowgroup-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

#fixTable>.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot,
#fixTable>.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead {
  display: table;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

#fixTable>.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot>tr,
#fixTable>.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead>tr {
  display: table-row;
}

#fixTable>.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead>tr>th {
  display: table-cell;
}

#fixTable>.p-datatable-virtual-table {
  position: absolute;
}

#fixTable>.p-datatable-resizable>.p-datatable-wrapper {
  overflow-x: auto;
}

#fixTable>.p-datatable-resizable .p-datatable-tbody>tr>td,
#fixTable>.p-datatable-resizable .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-resizable .p-datatable-thead>tr>th {
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
}

#fixTable>.p-datatable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

#fixTable>.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

#fixTable>.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5rem;
  height: 100%;
  padding: 0;
  cursor: col-resize;
  border: 1px solid transparent;
}

#fixTable>.p-datatable .p-column-header-content {
  display: -moz-box;
  align-items: center;
  margin: auto;
}

#fixTable>.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

#fixTable>.p-datatable .p-row-editor-cancel,
#fixTable>.p-datatable .p-row-editor-init,
#fixTable>.p-datatable .p-row-editor-save,
#fixTable>.p-datatable .p-row-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

#fixTable>.p-datatable-reorder-indicator-down,
#fixTable>.p-datatable-reorder-indicator-up {
  position: absolute;
  display: none;
}

#fixTable>.p-datatable-reorderablerow-handle,
.p-reorderable-column {
  cursor: move;
}

#fixTable>.p-datatable .p-datatable-loading-overlay {
  position: absolute;
  justify-content: center;
  z-index: 2;
}

#fixTable>.p-column-filter-row,
#fixTable>.p-datatable .p-datatable-loading-overlay {
  display: flex;
  align-items: center;
}

#fixTable>.p-column-filter-row {
  width: 100%;
}

#fixTable>.p-column-filter-menu {
  display: inline-flex;
  margin-left: auto;
}

#fixTable>.p-column-filter-row .p-column-filter-element {
  flex: 1 1 auto;
  width: 100%;
}

#fixTable>.p-column-filter-clear-button,
#fixTable>.p-column-filter-menu-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

#fixTable>.p-column-filter-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

#fixTable>.p-column-filter-row-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

#fixTable>.p-column-filter-row-item {
  cursor: pointer;
}

#fixTable>.p-column-filter-add-button,
#fixTable>.p-column-filter-remove-button {
  justify-content: center;
}

#fixTable>.p-column-filter-add-button .p-button-label,
#fixTable>.p-column-filter-remove-button .p-button-label {
  flex-grow: 0;
}

#fixTable>.p-column-filter-buttonbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#fixTable>.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
  width: auto;
}

#fixTable>.p-datatable .p-datatable-tbody>tr>td>.p-column-title {
  display: none;
}

#fixTable>.p-datatable .p-virtualscroller-loading {
  -webkit-transform: none !important;
  transform: none !important;
  min-height: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
}

#fixTable>.p-datatable.p-datatable-thead>tr>th .p-datatable #fixTable>.p-datatable-tbody>tr>td,
#fixTable>.p-datatable.p-datatable-tfoot>tr>td {
  text-align: center;
  display: block;
  width: 80px;
}

#fixTable>.p-datatable-scrollable .p-datatable-tbody>tr>td,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot>tr>td,
#fixTable>.p-datatable-scrollable .p-datatable-thead>tr>th {
  text-align: center;
  width: 80px;
}

#fixTable>table>tbody>tr {
  text-align: center;
  width: 100px;
}

#fixTable>table>tbody>tr>td {
  text-align: center;
  width: 100px;
}

/* #fixTable>.p-datatable .p-datatable-tfoot>tr>td
{
  background: antiquewhite;
} */

#fixTable>.p-datatable-scrollable .p-datatable-thead>tr>th,
#fixTable>.p-datatable-scrollable .p-datatable-tbody>tr>td,
#fixTable>.p-datatable-scrollable .p-datatable-tfoot>tr>td {
  text-align: center;
  width: 80px;
}

#fixTable>.p-datatable-scrollable .p-datatable-tbody>tr {
  text-align: center;
  width: 80px;
}

body .p-button {
  border-radius: 2px;
  overflow: hidden;
  background-color: #1e88e5;
  color: #ffffff;
  font-size: 1em;
  height: 2.143em;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  padding: 0 1em;
  /* border: 0 none; */
  /* -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; */
}

body .p-button.p-button-success,
body .p-buttonset.p-button-success>.p-button {
  color: #ffffff;
  background-color: #4caf50;
}

body .p-button.p-button-danger,
body .p-buttonset.p-button-danger>.p-button {
  background-color: #d32f2f;
  color: #ffffff;
}

.form-group {
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.orderlist-demo .product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.orderlist-demo .product-item img {
  width: 75px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 1rem;
}

.orderlist-demo .product-item .product-list-detail {
  flex: 1 1;
  text-align: left;
  font-weight: bold;
}

.orderlist-demo .product-item .product-list-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.orderlist-demo .product-item .product-category {
  vertical-align: middle;
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .orderlist-demo .product-item {
    flex-wrap: wrap;
  }

  .orderlist-demo .product-item .image-container {
    width: 100%;
    text-align: center;
  }

  .orderlist-demo .product-item img {
    margin: 0 0 1rem 0;
    width: 100px;
  }
}

/* #treeTable >  div > table > tbody > tr > td{ */

#gameInfoTable>div>ul {
  text-align: center;

  min-height: 70vh;
  max-height: 70vh;
}

.p-fluid .p-multiselect,
.p-orderlist,
.p-orderlist-controls {
  display: flex;
}

.p-orderlist-controls {
  flex-direction: column;
  justify-content: center;
}

.p-orderlist-list-container {
  flex: 1 1 auto;
}

.p-orderlist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 12rem;
  max-height: 24rem;
}

.p-orderlist-item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.p-orderlist.p-state-disabled .p-button,
.p-orderlist.p-state-disabled .p-orderlist-item {
  cursor: default;
}

.p-orderlist.p-state-disabled .p-orderlist-list {
  overflow: hidden;
}

.orderlist-demo .product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.orderlist-demo .product-item img {
  width: 75px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 1rem;
}

.orderlist-demo .product-item .product-list-detail {
  flex: 1 1;
}

.orderlist-demo .product-item .product-list-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.orderlist-demo .product-item .product-category {
  vertical-align: middle;
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .orderlist-demo .product-item {
    flex-wrap: wrap;
  }

  .orderlist-demo .product-item .image-container {
    width: 100%;
    text-align: center;
  }

  .orderlist-demo .product-item img {
    margin: 0 0 1rem;
    width: 100px;
  }
}

.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
  font-weight: 700;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.75rem 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.75rem 1.25rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, box-shadow 0.2s;
  transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #c7d2fe;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #4338ca;
  background: #eef2ff;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }

  .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
    flex-direction: row;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.p-card .p-card-body {
  padding: 1.25rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.p-card .p-card-content {
  padding: 1.25rem 0;
}

.p-card .p-card-footer {
  padding: 1.25rem 0 0 0;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

.table-bordered {
  border: 1px solid #f4f4f4;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  border: 1px solid #f4f4f4;
}

.list_view th {
  background-color: #dddddd;
  text-align: center;
}

.list_view .r_even {
  background-color: #ffffff;
}

.list_view .r_odd {
  background-color: #eeeeee;
}

.list_view .a_number {
  text-align: right;
}

.list_view .a_text {
  text-align: center;
}

.card {
  border-radius: 2px;
  background: #ffffff;
  padding: 1.25em;
  margin-bottom: 1em;
  box-sizing: border-box;
  font-size: 18px;
}

#SelectButton {
  display: flex;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #343a40;
}

.p-selectbutton .p-button.p-highlight {
  background: #6366f1;
  border-color: #6366f1;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #4f46e5;
  border-color: #4f46e5;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton.p-invalid>.p-button {
  border-color: #e24c4c;
}





/* 공지팝업 */
.pop01_popup1 {
  position: absolute;
  z-index: 1000000000;
}

.pop01_popup2 {
  position: absolute;
  z-index: 1000000000;
}

.pop01_popup_wrap {
  float: left;
  z-index: 1000000000;
}

.pop01_popup_btn_wrap {
  float: right;
  z-index: 1000000000;
}

.pop01_popup_btn_wrap ul li {
  float: left;
  margin: 0 0 0 5px;
}

.pop01_popup_btn {
  float: right;
  background: #3c8dbc;
  min-width: 60px;
  height: 36px;
  line-height: 40px;
  padding: 0 15px 0 15px;
  text-align: center;
  display: inline-block;
  font-family: nanumgothic, sans-serif;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.pop01_popup_box {
  float: left;
  background: #000000;
  border: 5px solid #3c8dbc;
  clear: both;
  z-index: 1000000000;
}

.pop01_popup_text {
  float: left;
  width: 100%;
  z-index: 1000000000;
}

.pop01_popup_font1 {
  float: left;
  width: 100%;
  font-family: 'nanumsquare', sans-serif;
  font-size: 22px;
  letter-spacing: -1px;
  font-weight: 700;
  color: #3c8dbc;
  line-height: 40px;
}

.pop01_popup_font2 {
  float: left;
  width: 100%;
  font-family: 'nanumgothic', sans-serif;
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}


.neon {
  animation: neon 1s ease infinite;
  -moz-animation: neon 1s ease infinite;
  -webkit-animation: neon 1s ease infinite;
}

@-webkit-keyframes neon {

  0%,
  100% {
    text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
    color: #FED128;
  }

  50% {
    text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
    color: #806914;
  }
}

@keyframes neon {

  0%,
  100% {
    text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
    color: #FED128;
  }

  50% {
    text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
    color: #806914;
  }
}



.p-datatable .p-column-header-content {
  display: block;
}


.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td {
  /* flex : auto */
  flex: auto;
  width: 100px;
  height: 30px;
}

.react-datepicker-popper[data-placement^=bottom] {
  z-index: 99999;
}

.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td,
.p-datatable-scrollable .p-datatable-thead>tr>th {
  /* display: block; */
  align-items: center;
  justify-content: center;
  width: 150px;
}





.login_tab_wrap {
  position: fixed;
  top: 46%;
  margin-top: -240px;
  right: 13.15%;
  width: 400px;
  min-height: 450px;
  padding: 0px 40px 40px 40px;
  background: #fff;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 97;
  height: 605px;
}

.tab-label {
  border-top: 3px solid #057FEB;
  width: 54px;
}

.login-title {
  position: relative;
  margin: 36px 0px;
  display: block;
  line-height: 32px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: -0.4px;
}

.login_int input {
  border: none;
  border-bottom: 2px solid #fff;
  width: calc(100% - 7px);
  line-height: 24px;
  font-size: 15px;
  color: #000;
  padding-left: 10px;
  outline: 0;
  transition: all .3s ease;
  -webkit-transition: all .3s ease
}

.login_int input:hover {
  border-bottom: 2px solid #000;
}

.login_int {
  padding: 0px;
  margin-top: 20px;
  border-bottom: 1px solid #999;
  transition: all .3s ease;
  -webkit-transition: all .3s ease
}

.login_int:hover {
  border-bottom: 1px solid #000;
}

.login-btn {
  position: absolute;
  width: 80px;
  height: 80px;
  line-height: 80px;
  top: 190px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 5px 14px 0 rgb(0 0 0 / 34%);
  cursor: pointer;
  z-index: 10;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.chk_btn {
  display: inline-block;
  padding: 20px 0px 20px 20px;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: -0.4px;
  /* background: url("https//cloudfront-image.kantukan.co.kr/mall/crmtest/log_arr.png") no-repeat left center; */
}

.save_wrap label {
  font-size: 15px;
  margin: 0px 0px 2px 3px;
  letter-spacing: -0.4px;
}
